import React, {useEffect, useState} from "react"
import {
	CardElement as StripeCardElement,
	Elements,
	PaymentRequestButtonElement,
	useElements,
} from "@stripe/react-stripe-js"
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	Link,
	OutlinedInput,
	Typography,
} from "@material-ui/core"
import styled from "styled-components"
import {useNotification} from "@indebted/components/Notification"
import {AcceptedCards} from "@indebted/components/AcceptedCards"
import {useModal} from "@indebted/components/Modal"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {useParams} from "@reach/router"
import {TextToHTML} from "@indebted/components/TextToHTML"
import {customerAPI} from "@indebted/api"
import {BankAccountIcon} from "@indebted/assets"
import ApplePay from "@indebted/assets/ApplePay.svg"
import digitalWalletAppleIcon from "@indebted/assets/apple.svg"
import digitalWalletGoogleIcon from "@indebted/assets/google.svg"
import GooglePay from "@indebted/assets/Gpay.svg"

import "regenerator-runtime/runtime"
import {Element} from "./Element"
import {Api} from "./api"
import {EFTAConfirmationDialog} from "./EFTAConfirmationDialog"
import {initWalletPay, walletPayAPI} from "./walletPay"
import {EFTAConfirmationDialogForDigitalWallet} from "./EFTAConfirmationDialogForDigitalWallet"

function DebitCardWithEFTAForm({stripe, locale, ...props}) {
	return (
		<Elements stripe={stripe} options={{locale}}>
			<InnerForm stripe={stripe} {...props} />
		</Elements>
	)
}

// eslint-disable-next-line complexity
function InnerForm({
	stripe,
	onSubmitEFTA,
	i18n,
	termsOfDiscountOffer,
	hidePostalCode,
	acceptedCardsList,
	morePaymentMethodsAvailable,
	walletPay,
	clientSecret,
	walletPayMethods,
	paymentMethodSelectionLink,
	eventPrefix,
	statusURL,
	providerData,
	paymentPlanID,
	useNewDigitalWalletFlow,
	requestESIGNConsent,
	esignConsent,
}) {
	const {notification} = useNotification()
	const [submitting, setSubmitting] = useState(false)
	const [form, setForm] = useState({Email: "", Name: "", ESIGNConsent: false})
	const [esignConsentEnabled, setESIGNConsentEnabled] = useState(true)
	const elements = useElements()
	const [paymentRequest, setPaymentRequest] = useState(null)
	const {secureCode} = useParams()
	const [paymentMethod, setPaymentMethod] = useState(null)
	const [last4CardDigits, setLast4CardDigits] = useState("")
	const [openEFTADigitalWalletModal, setOpenEFTADigitalWalletModal] = useState(false)
	const [applePayEnabled, setApplePayEnabled] = useState(false)
	const [googlePayEnabled, setGooglePayEnabled] = useState(false)
	const [showDigitalWalletButton, setShowDigitalWalletButton] = useState(false)
	const [showESIGNErrorMessage, setShowESIGNErrorMessage] = useState(false)

	const handleInput =
		(name) =>
		({target: {value}}) => {
			setForm({...form, [name]: value})
		}

	const debitCardElement = elements.getElement(StripeCardElement)

	const stripeAPI = Api({
		stripeSDK: stripe,
		form,
		debitCardElement,
		fundingTypeErrorMessage: i18n.FormErrorsFundingType,
	})

	const walletAPI = walletPayAPI({
		walletPayMethods,
		stripe,
		stripeAPI,
		walletPay,
		secureCode,
		clientSecret,
		i18n,
		eventPrefix,
		notification,
		statusURL,
	})

	useEffect(() => {
		walletAPI.init()
		walletAPI.enabledWallet().then(({applePay, googlePay}) => {
			setShowDigitalWalletButton(applePay || googlePay)
			if (applePay) {
				setApplePayEnabled(true)
			}
			if (googlePay) {
				setGooglePayEnabled(true)
			}
		})
	}, [walletAPI])

	const EFTAConfirmationModal = useModal({
		onClose: () => {
			setSubmitting(false)
		},
	})

	// eslint-disable-next-line complexity
	const submit = async (event) => {
		event.preventDefault()

		// See https://stripe.com/docs/stripe-js/react#useelements-hook
		if (!stripe || !elements) {
			return
		}

		const emailValidationRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (form.Name.trim() == "" || !form.Email.match(emailValidationRegex)) {
			notification.error(i18n.FormErrorsEmailNameValidation, 5000)
			return
		}

		if (requestESIGNConsent && !form.ESIGNConsent) {
			setShowESIGNErrorMessage(true)
			return
		}

		setSubmitting(true)

		try {
			if (requestESIGNConsent && esignConsentEnabled) {
				const customer = await customerAPI.find({secureCode})
				await customerAPI.grantESIGNConsent({customerID: customer.ID})
				setESIGNConsentEnabled(false)
			}
			const result = await stripeAPI.createPaymentMethod()
			setPaymentMethod(result.paymentMethod)
			setLast4CardDigits(result.paymentMethod.card.last4)
			EFTAConfirmationModal.Open()
		} catch (error) {
			notification.error(error.message || error.Message, 5000)
			setSubmitting(false)
		}
	}

	useEffect(
		() => {
			initWalletPay({
				walletPayMethods,
				stripe,
				stripeAPI,
				walletPay,
				secureCode,
				clientSecret,
				i18n,
				eventPrefix,
				notification,
				statusURL,
				setPaymentRequest,
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const onChangeESIGNConsentCheckbox = ({target: {checked}}) => {
		setForm({...form, ["ESIGNConsent"]: checked})
		setShowESIGNErrorMessage(!checked)
	}

	return (
		<form onSubmit={submit}>
			<EFTAConfirmationDialog
				modal={EFTAConfirmationModal}
				onSubmitEFTA={onSubmitEFTA}
				onCancel={() => {
					setSubmitting(false)
				}}
				stripeAPI={stripeAPI}
				i18n={i18n}
				paymentMethod={paymentMethod}
				last4CardDigits={last4CardDigits}
				secureCode={secureCode}
				stripe={stripe}
				debitCardElement={debitCardElement}
				form={form}
				providerData={providerData}
				paymentPlanID={paymentPlanID}
			/>
			<EFTAConfirmationDialogForDigitalWallet
				onCancel={() => {
					setOpenEFTADigitalWalletModal(false)
				}}
				i18n={i18n}
				open={openEFTADigitalWalletModal}
				paymentRequest={paymentRequest}
				icon={applePayEnabled ? ApplePay : GooglePay}
			/>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item container>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
								<OutlinedInput
									id="fullname"
									type="text"
									label={i18n.FormFullNameLabel}
									value={form.Name}
									onChange={handleInput("Name")}
									required
									autoFocus
								/>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl variant="outlined" fullWidth={true}>
								<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
								<OutlinedInput
									id="email"
									label={i18n.FormEmailLabel}
									type="email"
									value={form.Email}
									onChange={handleInput("Email")}
									variant="outlined"
									required
								/>
								<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item>
							<Element hidePostalCode={hidePostalCode} />
							<AcceptedCards list={acceptedCardsList} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
						{termsOfDiscountOffer?.AndText}
						<TermsOfDiscountOffer linkLabel={termsOfDiscountOffer?.ModalLinkLabel} />
					</Typography>
				</Grid>
				{requestESIGNConsent && (
					<Grid item>
						<Grid container direction="column" alignItems="stretch" spacing={5}>
							<Grid item>
								<StyledFormControlLabel
									control={
										<StyledCheckbox
											disabled={!esignConsentEnabled}
											onChange={onChangeESIGNConsentCheckbox}
											name="esign-consent"
											color="primary"
											error={showESIGNErrorMessage}
										/>
									}
									label={
										<Typography variant="p" color="textPrimary" component="p">
											{esignConsent.CheckboxText} {esignConsent.SeeOurTermsAndConditionsText}
											<Link
												href={esignConsent.TermsAndConditionsURL}
												rel="noopener noreferrer"
												target="_blank"
											>
												{esignConsent.TermsAndConditionsLabel}
											</Link>
										</Typography>
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}
				{showESIGNErrorMessage && (
					<Grid item>
						<StyledAlertGrid item>
							<TextToHTML text={esignConsent.ErrorMessage} />
						</StyledAlertGrid>
					</Grid>
				)}
				<Grid item>
					<Button fullWidth type="submit" disabled={!stripe || submitting}>
						{i18n.FormButtonLabel}
					</Button>
				</Grid>

				<>
					{useNewDigitalWalletFlow && showDigitalWalletButton && (
						<Grid item>
							<StyledDigitalWalletButton
								fullWidth
								type="button"
								onClick={() => {
									setOpenEFTADigitalWalletModal(true)
								}}
							>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item>Pay with digital wallet</Grid>
									<Grid item>
										<Grid container alignItems="center">
											{applePayEnabled && (
												<StyledDigitalWalletIcon src={digitalWalletAppleIcon} height="18px" />
											)}
											{googlePayEnabled && (
												<StyledDigitalWalletIcon src={digitalWalletGoogleIcon} height="18px" />
											)}
										</Grid>
									</Grid>
								</Grid>
							</StyledDigitalWalletButton>
						</Grid>
					)}
				</>
				<>
					{!useNewDigitalWalletFlow && paymentRequest && (
						<Grid item>
							<PaymentRequestButtonElement options={{paymentRequest}} />
						</Grid>
					)}
				</>
				{morePaymentMethodsAvailable && (
					<Grid
						item
						style={{paddingTop: "3px", paddingRight: "5px", display: "flex", alignItems: "flex-start"}}
					>
						<BankAccountIcon />
						<Typography style={{marginLeft: "5px"}}>
							{i18n.FormPaymentMethodSelectionText}
							<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
								{i18n.FormPaymentMethodSelectionLinkText}
							</Link>
						</Typography>
					</Grid>
				)}
			</Grid>
		</form>
	)
}

const StyledDigitalWalletButton = styled(Button)`
	background-color: #000;
	color: #fff;

	&:hover {
		background-color: #252525;
	}
`

const StyledDigitalWalletIcon = styled.img`
	margin: 0 0 0 10px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
	align-items: start;
`

const StyledCheckbox = styled(Checkbox)`
	padding-top: 0;

	svg {
		color: ${(props) => (props.error ? "#D7153A" : "inherit")};
	}
`

const StyledAlertGrid = styled(Grid)`
	border: solid 1px #d7153a;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 10px;

	p {
		font-size: 0.75rem;
		color: #d7153a;
	}
`

export {DebitCardWithEFTAForm}
