import React from "react"
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	Link,
	OutlinedInput,
	Typography,
} from "@material-ui/core"
import {TermsOfDiscountOffer} from "@indebted/components/TermsOfDiscountOffer"
import {DebitCardIcon} from "@indebted/assets"

function FormInputs({
	form,
	handleInput,
	handleCheckboxInput,
	onSubmit,
	i18n,
	termsOfDiscountOffer,
	paymentMethodSelectionLink,
}) {
	function submit(event) {
		event.preventDefault()
		onSubmit()
	}

	const isSortCodeInvalid = form.SortCodeDirty && !/^\d{6}$/g.test(form.SortCode)
	const isAccountNumberInvalid = form.AccountNumberDirty && !/^\d{7,8}$/g.test(form.AccountNumber)

	return (
		<form onSubmit={submit}>
			<Grid container direction="column" alignItems="stretch" spacing={5}>
				<Grid item>
					<Grid container direction="column" alignItems="stretch" spacing={7}>
						<Grid item>
							<Grid container direction="column" alignItems="stretch" spacing={3}>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="fullname">{i18n.FormFullNameLabel}</InputLabel>
										<OutlinedInput
											id="fullname"
											type="text"
											label={i18n.FormFullNameLabel}
											value={form.Name}
											onChange={handleInput("Name")}
											required
											autoFocus
										/>
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="email">{i18n.FormEmailLabel}</InputLabel>
										<OutlinedInput
											id="email"
											label={i18n.FormEmailLabel}
											type="email"
											value={form.Email}
											onChange={handleInput("Email")}
											variant="outlined"
											required
										/>
										<FormHelperText>{i18n.FormEmailHelp}</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="column" alignItems="stretch" spacing={3}>
								<Grid item>
									<Typography component="p">Account Details</Typography>
								</Grid>
								<Grid item>
									<FormControl error={isSortCodeInvalid} variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="sort-code">{i18n.FormSortCodeLabel}</InputLabel>
										<OutlinedInput
											id="sort-code"
											label={i18n.FormSortCodeLabel}
											type="text"
											value={form.SortCode}
											onChange={handleInput("SortCode")}
											variant="outlined"
											required
											onBlur={() => handleInput("SortCodeDirty")({target: {value: true}})}
										/>
										{isSortCodeInvalid && (
											<FormHelperText error>must be a 6-digit number</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl error={isAccountNumberInvalid} variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="account-number">{i18n.FormAccountNumberLabel}</InputLabel>
										<OutlinedInput
											id="account-number"
											label={i18n.FormAccountNumberLabel}
											type="text"
											value={form.AccountNumber}
											onChange={handleInput("AccountNumber")}
											variant="outlined"
											required
											onBlur={() => handleInput("AccountNumberDirty")({target: {value: true}})}
										/>
										{isAccountNumberInvalid && (
											<FormHelperText error>must be a 7 or 8 digit number</FormHelperText>
										)}
									</FormControl>
								</Grid>
							</Grid>
						</Grid>

						<Grid item>
							<Grid container direction="column" alignItems="stretch" spacing={3}>
								<Grid item>
									<Typography component="p">Billing Address</Typography>
								</Grid>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="address-line-1">{i18n.FormAddressLine1Label}</InputLabel>
										<OutlinedInput
											id="address-line-1"
											label={i18n.FormAddressLine1Label}
											type="text"
											value={form.AddressLine1}
											onChange={handleInput("AddressLine1")}
											variant="outlined"
											required
										/>
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="address-line-2">{i18n.FormAddressLine2Label}</InputLabel>
										<OutlinedInput
											id="address-line-2"
											label={i18n.FormAddressLine2Label}
											type="text"
											value={form.AddressLine2}
											onChange={handleInput("AddressLine2")}
											variant="outlined"
										/>
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="city">{i18n.FormCityLabel}</InputLabel>
										<OutlinedInput
											id="city"
											label={i18n.FormCityLabel}
											type="text"
											value={form.City}
											onChange={handleInput("City")}
											variant="outlined"
											required
										/>
									</FormControl>
								</Grid>
								<Grid item>
									<FormControl variant="outlined" fullWidth={true}>
										<InputLabel htmlFor="postcode">{i18n.FormPostcodeLabel}</InputLabel>
										<OutlinedInput
											id="postcode"
											label={i18n.FormPostcodeLabel}
											type="text"
											value={form.Postcode}
											onChange={handleInput("Postcode")}
											variant="outlined"
											required
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								checked={form.PayerAgreement}
								onChange={handleCheckboxInput("PayerAgreement")}
								name="checked-payer-agreement"
								color="primary"
								required
							/>
						}
						label={
							<Typography variant="caption" color="textSecondary" component="p">
								{i18n.FormPayerAgreementText}
							</Typography>
						}
					/>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p" align="center">
						{i18n.FormTermsAndConditionsText}{" "}
						<Link href={i18n.FormTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormTermsAndConditionsLinkText}
						</Link>
						{termsOfDiscountOffer?.AndText}
						<TermsOfDiscountOffer linkLabel={termsOfDiscountOffer?.ModalLinkLabel} />
					</Typography>
				</Grid>
				<Grid item>
					<Button fullWidth type="submit">
						{i18n.FormContinueToConfirmationButtonLabel}
					</Button>
				</Grid>
				<Grid item style={{paddingTop: "3px", paddingRight: "5px", display: "flex", alignItems: "flex-start"}}>
					<DebitCardIcon />
					<Typography style={{marginLeft: "5px"}}>
						{i18n.FormPaymentMethodSelectionText}
						<Link href={paymentMethodSelectionLink} rel="noopener noreferrer">
							{i18n.FormPaymentMethodSelectionLinkText}
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p">
						{i18n.FormDirectDebitGuaranteeText}{" "}
						<Link href={i18n.FormDirectDebitGuaranteeLink} target="_blank" rel="noopener noreferrer">
							{i18n.FormDirectDebitGuaranteeLinkText}
						</Link>
					</Typography>
				</Grid>

				<Grid item>
					<Typography variant="caption" color="textSecondary" component="p">
						{i18n.InDebtedAddressText}
					</Typography>
					<Link href={"mailto:" + i18n.CustomerSupportEmailLink}>{i18n.CustomerSupportEmailLink}</Link>
				</Grid>
			</Grid>
		</form>
	)
}

export {FormInputs}
